<template>
  <div class="projects-info-list item-list item-report-summary">
    <div
      class="summary-toolbar"
      v-if="title"
    >
      <h2>{{title}} ({{total}})</h2>
    </div>

    <table class="no-row-border">
      <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>E.V</th>
          <th>P</th>
          <th>Company</th>
          <th>Created</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="project in projects"
          :key="project.key"
        >
          <td @click="$emit('on-project-click', project)">
            <button class="max-char-width w28">{{project.name}}</button>
          </td>
          <td>
            <a class="max-char-width w28" :href="'mailto:'+project.email">
            {{project.email}}
            </a>
          </td>
          <td>
            {{project.email_verified ? '✅' : 'No'}}
          </td>
          <td>{{project.no_of_projects}}</td>
          <td>
            {{project.company_name ? project.company_name : '-'}}
          </td>
           <td>
            {{ $filters.relativeShortDate(project.created) }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  props: {
    projects: Object,
    title: {
      type: String,
      default: '',
    },
    total: {
      type: String,
      default: '',
    },
  },
};
</script>
