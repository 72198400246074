<template>
  <div class="screen-admin-page screen-admin-usaga-report">
    <div>
      <div class="toolbar">
        <div class="title">
          <h1>Users</h1>
          <div class="search-overlay" v-if="data.openSearchTab">
            <div class="wrapper">
              <div class="header">
                <input type="text" v-model="data.inputVal" placeholder="Search">
                <button class="close-btn" @click="close()">X</button>
              </div>
              <div v-if="data.suggestionList" class="suggestion-list"
                :class="data.suggestionList && data.suggestionList.docs &&
                data.suggestionList.docs.length > 0 ? 'add-height': ''">
                <div>
                  <a :href="consoleLink+'admin/users-info/'+item.key+'/preview?search='+data.inputVal"
                    v-for="item in data.suggestionList.docs" :key="item.key" class="list-wrapper">
                    <div class="align-left">
                      <span>{{item.name}}</span>
                    </div>
                    <div class="align-right">
                      <span>{{item.key}}</span>
                      <span>{{item.email}}</span>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tools">
          <span class="search-icon"  @click="data.openSearchTab = true">
            Search
            <svg>
              <use v-bind="{'xlink:href':'#search-icon'}" />
            </svg>
          </span>
        </div>
      </div>
    </div>
    <rz-request-state :state="state" />
    <div
      class="usage-report admin-screen-content"
      v-if="state.status.isLoaded"
    >
      <div class="item-list-row item-list">
        <div>
          <CustomerTable
            :title="title"
            :total="totalItems"
            :projects="filteredProjects"
            @on-project-click="showPreviewProject"
          />

          <div
            v-if="pages"
            class="filter-pagination"
          >
            <ul>
              <li
                v-for="page in pages"
                :key="page"
              >
                <router-link
                  class="btn"
                  :to="{query: {...$route.query, page: page}}"
                >{{page}}</router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <template v-if="previewProject">
      <QuickPreviewUser />
    </template>
  </div>
</template>
<script>
import {
  ref,
  onMounted,
  computed,
  watch,
  reactive,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { RequestState, RequestStateStatus } from '@/api/core';
import {
  UsersReport,
  ProjectList,
} from '@/store/modules/admin';
import CustomerTable from '@/components/admin/CustomerTable.vue';
import QuickPreviewUser from '@/components/admin/QuickPreviewUser.vue';
import Config from '@/config';

export default {
  components: {
    CustomerTable,
    QuickPreviewUser,
  },
  data() {
    return {
      consoleLink: Config.consoleHost,
    };
  },
  setup() {
    const store = useStore();
    const pageLimit = 150;
    const route = useRoute();
    const router = useRouter();
    const state = ref(new RequestState());
    const projects = ref({});
    const previewProject = ref({});
    const filteredProjects = ref([]);
    const itemGroup = ref(route.query.group || 'active');
    const title = ref('Users');
    const page = ref(route.query.page || 1);

    const showPreviewProject = (project) => {
      previewProject.value = project;
      router.push({
        name: 'adminUserPreview',
        params: { key: project.user },
        query: {
          limit: pageLimit,
          page: page.value,
        },
      });
    };
    // Need this function to load project using query param project key
    // eslint-disable-next-line no-unused-vars

    const hidePreviewProject = () => {
      previewProject.value = {};
      router.push({
        name: 'adminUsersInfo',
      });
      store.commit('admin/updatePreviewUser', { project: null });
      return false;
    };
    const data = reactive({
      openSearchTab: false,
      inputVal: null,
      suggestionList: {},
    });

    const close = () => {
      data.suggestionList = null;
      data.inputVal = '';
      data.openSearchTab = false;
    };

    watch(() => data.inputVal, () => ProjectList.searchQuery(data.inputVal, 'user').then((list) => {
      data.suggestionList = list.data.data;
    }));

    const fetchData = () => {
      state.value.status = RequestStateStatus.Loading;
      let params = {};
      params = {
        limit: pageLimit,
        page: page.value,
        ...params,
      };
      return UsersReport.filterQuery(params).then((customerList) => {
        projects.value = customerList;
        filteredProjects.value = [...customerList.customers];
        state.value.status = RequestStateStatus.Loaded;
      }).catch((err) => {
        state.value.ref = err;
        state.value.status = RequestStateStatus.Error;
      });
    };
    onMounted(() => {
      fetchData();
    });

    const totalItems = computed(() => {
      if (projects.value.page.total_items) {
        return `${projects.value.page.total_items}`;
      }
      let length = `${projects.value.customers.length}`;
      if (projects.value.page && projects.value.page.total_pages > 1) {
        length = `${length}+`;
      }
      return length;
    });
    const pages = computed(() => {
      if (projects.value.page && projects.value.page.total_pages > 1) {
        const currentPage = parseInt(page.value, 10);
        const total = projects.value.page.total_pages;
        const maxPageItems = 10;
        const items = [];
        let left = 0;
        let right = Math.min(total, maxPageItems);
        if (total > maxPageItems) {
          left = Math.max(0, currentPage - (maxPageItems / 2));
          right = left + maxPageItems - 1;
        }
        for (let i = left; i < right; i += 1) {
          items.push(i + 1);
        }
        if (items[0] !== 1) {
          items.splice(0, 0, 1);
        }
        if (items[items.length - 1] !== total) {
          items.push(total);
        }
        return items;
      }
      return null;
    });

    watch(() => route.query, () => {
      if (route.query.group || route.query.page) {
        itemGroup.value = route.query.group || 'active';
        page.value = route.query.page || 1;
        fetchData();
      }
    });
    const onPreviewUpdate = (project) => {
      previewProject.value = project;
    };
    return {
      state,
      projects,
      pages,
      totalItems,
      title,
      filteredProjects,
      itemGroup,
      onPreviewUpdate,
      showPreviewProject,
      hidePreviewProject,
      previewProject,
      data,
      close,
    };
  },
};
</script>
